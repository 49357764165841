<template>
    <div class="box">
        <myBreadcrumb :nav="nav"></myBreadcrumb>
        <div class="introduction">
            <!--            <video autoplay>-->
            <!--                <source :src="info.vedioUrl" type="video/mp4" />-->
            <!--            </video>-->
            <embed :src="info.vedioUrl" height="200" width="200" />
            <div class="right">
                <div class="title">{{ info.name || '' }}</div>
                <!--                <div class="teacher">{{ info.createTime }}</div>-->
                <div class="time">
                    <img src="@/assets/images/Frame-37.png" alt="" />
                    {{ info.createTime || '' }}
                </div>
                <div class="reading">
                    <img src="@/assets/images/Frame-38.png" alt="" />
                    {{ info.readCount || 0 }}
                </div>
                <div class="line"></div>
                <div class="video-title">
                    <!--                    <div>视频简介</div>-->
                    <div style='white-space: pre-wrap;' class="htmls" v-html="info.consultingDetail ? info.consultingDetail.replace(/<img/g, '<img style=width:100%;height:auto; ') : ''"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import  《组件名称》  from '《组件路径》 ';
import { getConsultingDetail } from '@/api';
export default {
    // import 引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        // 这里存放数据
        return {
            nav: [
                {
                    title: '首页',
                    to: { path: '/' },
                },
                {
                    title: '资讯动态',
                    to: { path: '/consultationDynamicList' },
                },
                {
                    title: '详情',
                },
            ],
            info: {},
            playIndex: 0,
        };
    },
    props: {},
    // 方法集合
    methods: {
        getBrightspaceDetails() {
            getConsultingDetail(this.$route.query.id).then((res) => {
                console.log(res);
                this.info = res.data;
            });
        },
    },
    // 计算属性 类似于 data 概念
    computed: {},
    // 监控 data 中的数据变化
    watch: {},
    //过滤器
    filters: {},
    // 生命周期 - 创建之前
    beforeCreate() {},
    // 生命周期 - 创建完成（可以访问当前this 实例）
    created() {
        this.getBrightspaceDetails();
    },
    // 生命周期 - 挂载之前
    beforeMount() {},
    // 生命周期 - 挂载完成（可以访问 DOM 元素）
    mounted() {},
    // 生命周期 - 更新之前
    beforeUpdate() {},
    // 生命周期 - 更新之后
    updated() {},
    // 生命周期 - 销毁之前
    beforeDestroy() {},
    // 生命周期 - 销毁完成
    destroyed() {},
    // 如果页面有 keep-alive 缓存功能,这个函数会触发
    //进入的时候触发
    activated() {},
    //离开的时候触发
    deactivated() {},
};
</script>

<style lang="less" scoped>
.introduction {
    display: flex;
    embed {
        width: 900px;
        height: 506.25px;
    }
    .right {
        border: 1px solid #ebebeb;
        //margin-left: 20px;
        overflow: hidden;
        flex: 1;
        padding: 12px 16px;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        .title {
            font-size: 20px;
            font-weight: bold;
            color: #333333;
        }
        .teacher {
            font-size: 14px;
            color: #999999;
            margin-top: 10px;
        }
        .time {
            font-size: 14px;
            color: #999999;
            margin-top: 30px;
            display: flex;
            align-items: center;
            img {
                width: 14px;
                height: 14px;
                margin-right: 5px;
            }
        }
        .reading {
            font-size: 14px;
            color: #999999;
            margin-top: 10px;
            display: flex;
            align-items: center;
            img {
                width: 14px;
                height: 14px;
                margin-right: 5px;
            }
        }
        .line {
            width: 100%;
            height: 1px;
            background: #e5e5e5;
            margin-top: 20px;
        }
    }
}
.w-title {
    margin-top: 24px;
    font-weight: 700;
    font-size: 24px;
    color: #333333;
}
.w-content {
    font-size: 16px;
    margin-top: 12px;
    color: #666666;
}
.video-title {
    //div:nth-child(1) {
    //    font-weight: 700;
    //    font-size: 15px;
    //    color: #333333;
    //    margin: 15px 0;
    //}
    .htmls {
        width: 80%;
        padding: 10px 30px;
        // font-weight: 400;
        // font-size: 12px;
        color: #666666;
        height: max-content;
    }
}
::v-deep .ql-align-justify {
    text-align: justify;
}

::v-deep .ql-size-small {
    font-size: .75em;
}

::v-deep .ql-size-large {
    font-size: 1.5em;
}

::v-deep .ql-size-huge {
    font-size: 2.5em;
}
</style>
